import React from 'react';
import { get } from '../../utils/request';
import { Spinner } from 'react-bootstrap';
import "../../dataProvider/buckets.js";
import Alert from "../../component/Alert/Alert";
import Badge from "../../component/Badge/Badge";
import Button from "../../component/Button/Button";
import Layout from "../../component/Layout/Layout";
import Link from "../../component/Link/Link";
import "../../component/Table/Table.scss"

import {faUser, faCogs} from "@fortawesome/free-solid-svg-icons";

const filesize = require('filesize');
const dayjs = require('dayjs');

const dateFormat = 'DD.MM.YYYY';

class Accounts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            errorMessage: "",
            loading: true,
            loadingSuccess: false,
            successMessage: "",
        };
        this.loadData = this.loadData.bind(this);
    }

    async loadData() {
        const [res, err] = await get(this, "/partner/auth/account");
        if (err) {
            this.setState({errorMessage: "error: " + err});
            this.setState({loading: false});
            return
        }
        this.setState({data: res.data});
        this.setState({loading: false});
        this.setState({loadingSuccess: true});
    }

    componentDidMount() {
        this.loadData();
    }


    render() {
        const { data, errorMessage, loading, loadingSuccess, successMessage } = this.state;
        const { history } = this.props;
        function periodDateFormatter(date) {
            return dayjs(new Date(date.substr(0, 10))).format(dateFormat);
        }

        return <Layout activeMenu="/accounts" isLoggedIn={true}>
            {loading &&
            <>
                <Spinner animation="border" variant="primary"/> Loading accounts...
                <br/><br/>
            </>
            }
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            {successMessage && <Alert variant="success">{successMessage}</Alert>}
            {loadingSuccess &&
            <>
                <h1>Accounts</h1>
                <div className="d-flex">
                <Button className="primary btn btn-margin btn-right" faIcon={faUser} label="Create
                account" onClick={() => history.push('/accounts/NewAccount')}/>
            </div>
                {data.length > 0 &&
                    <table className="table-ctr">
                        <thead>
                        <tr>
                            <th>Company</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>ExternalID</th>
                            <th>Usage</th>
                            <th>Status</th>
                            <th>Created</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {data && data.map((value, index) => {
                            let statusBadge = "";
                            if(value.status === "waiting_activation") {
                                statusBadge = <Badge className="badge-warning-filled" label="waiting for activation"/>;
                            } else if (value.status === "active") {
                                statusBadge = <Badge className="badge-success-filled" label="active"/>;
                            } else if (value.status === "waiting_confirm") {
                                statusBadge = <Badge className="badge-warning-filled" label="waiting for confirmation"/>;
                            } else if (value.status === "closed") {
                                statusBadge = <Badge className="badge-danger-filled" label="closed"/>;
                            }

                            return <tr key={index}>
                                <td data-label={"Company"}>{value.company}</td>
                                <td data-label={"Name"}>{value.name}</td>
                                <td data-label={"Email"}>{value.email}</td>
                                <td data-label={"ExternalID"}>{value.externalId}</td>
                                <td data-label={"Usage"}>{filesize(value.usage) + " of " + filesize(value.usageLimit)}</td>
                                <td data-label={"Status"}>{statusBadge}</td>
                                <td data-label={"Created"}>{periodDateFormatter(value.created)}</td>
                                <td><Link className="btn primary-outline" faIcon={faCogs} href={"/accounts/" + value.id + "/view"} label="View account"/></td>
                             </tr>
                        })}
                        </tbody>
                    </table>
                }
            </>
            }
        </Layout>;
    }
}

export default Accounts